import React from 'react';
import { motion } from 'framer-motion';

interface ServiceCardProps {
  title: string;
  description: string;
  price: string;
  icon: JSX.Element;
  delay: number;
}

const ServiceCard: React.FC<ServiceCardProps> = ({ title, description, price, icon, delay }) => {
  return (
    <motion.div
      className="service-card"
      initial={{ opacity: 0, y: 10 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true, amount: 0.3 }}
      transition={{ duration: 0.4, delay: delay * 0.5 }}
    >
      <div className="flex items-start">
        <div className="flex-shrink-0 bg-primary rounded-none p-4 mr-5">
          <div className="text-white w-8 h-8">
            {icon}
          </div>
        </div>
        <div>
          <h3 className="text-xl font-bold mb-2">{title}</h3>
          <p className="text-gray-700 mb-4">{description}</p>
          <p className="font-bold text-primary">{price}</p>
        </div>
      </div>
    </motion.div>
  );
};

const Services: React.FC = () => {
  return (
    <section id="services" className="py-24 bg-secondary">
      <div className="container-custom">
        <motion.div
          className="mb-16 text-center"
          initial={{ opacity: 0, y: 10 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.4 }}
        >
          <h2 className="text-4xl font-bold mb-4">Services</h2>
          <p className="text-xl max-w-3xl mx-auto text-gray-700">Specializing in digital product strategy, website creation, and go-to-market services.</p>
        </motion.div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
          <ServiceCard
            title="One-Off Projects"
            description="For small projects, conversion rate optimization, digital audits, Go-To-Market strategy, and process improvements."
            price="100 €/h"
            icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
            </svg>}
            delay={0.1}
          />
          
          <ServiceCard
            title="Custom Website Creation"
            description="From conception to production on Squarespace. Includes domain setup, custom emails, and complete website design."
            price="Min. 1000 € excl. toolset"
            icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
            </svg>}
            delay={0.2}
          />
          
          <ServiceCard
            title="Product Management"
            description="Long-term product management services with proven positive impact. Expertise in digital product strategy and execution."
            price="Monthly rate starting from 5500 €"
            icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
            </svg>}
            delay={0.3}
          />
          
          <ServiceCard
            title="AI Strategy and Automation"
            description="Automate your business processes with AI. I help you identify the most impactful use cases and implement them. I can also build custom Agents or AI powered tools"
            price="Starting at 2500 €"
            icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
            </svg>}
            delay={0.4}
          />
        </div>
        
        <motion.div 
          className="mt-16 flex flex-col md:flex-row items-center justify-center gap-6"
          initial={{ opacity: 0, y: 10 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.4, delay: 0.2 }}
        >
          <div className="flex items-center justify-center">
            <img 
              src={require('../assets/images/Badge_Circle-Member(Black).png')} 
              alt="Squarespace Circle Member" 
              className="w-auto h-32" 
            />
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default Services; 