import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const CookieBanner: React.FC = () => {
  const [showBanner, setShowBanner] = useState(false);
  
  useEffect(() => {
    // Check if user has already acknowledged the cookie banner
    const isBannerDismissed = localStorage.getItem('cookieBannerDismissed');
    
    if (!isBannerDismissed) {
      // Show banner after a short delay
      const timer = setTimeout(() => {
        setShowBanner(true);
      }, 1500);
      
      return () => clearTimeout(timer);
    }
  }, []);
  
  const dismissBanner = () => {
    setShowBanner(false);
    localStorage.setItem('cookieBannerDismissed', 'true');
  };
  
  if (!showBanner) return null;
  
  return (
    <motion.div
      className="fixed bottom-0 left-0 right-0 md:bottom-8 md:left-1/2 md:right-auto md:transform md:-translate-x-1/2 bg-white shadow-xl px-4 py-5 md:p-6 z-50 md:max-w-xl w-full md:w-11/12 border-t border-gray-200 md:border-none md:rounded-none"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4 }}
    >
      <div className="flex flex-col md:flex-row md:items-center gap-3 md:gap-4">
        <div className="flex-grow">
          <p className="text-gray-800 font-medium text-sm md:text-base">We do not use any cookies</p>
          <p className="text-gray-600 text-xs md:text-sm mt-1">
            This website doesn't track or store any information about your visit.
          </p>
        </div>
        <button
          onClick={dismissBanner}
          className="bg-primary text-white px-4 py-2 md:px-5 md:py-2 text-sm md:text-base font-medium hover:bg-opacity-90 transition-colors duration-300 flex-shrink-0 whitespace-nowrap w-full md:w-auto mt-2 md:mt-0"
        >
          Got it
        </button>
      </div>
    </motion.div>
  );
};

export default CookieBanner; 