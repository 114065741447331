import React from 'react';

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();
  
  return (
    <footer className="bg-primary text-white py-12">
      <div className="container-custom">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <h3 className="text-xl font-bold mb-4">Sahil Singh</h3>
            <p className="max-w-xs">
              Freelance Product Manager & Digital Strategist based in Amsterdam, Netherlands.
            </p>
          </div>
          
          <div>
            <h3 className="text-xl font-bold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li><a href="#services" className="hover:text-accent transition-colors duration-300">Services</a></li>
              <li><a href="#clients" className="hover:text-accent transition-colors duration-300">Previous Clients</a></li>
              <li><a href="#contact" className="hover:text-accent transition-colors duration-300">Contact</a></li>
            </ul>
          </div>
          
          <div>
            <h3 className="text-xl font-bold mb-4">Contact</h3>
            <p className="mb-2">Amsterdam, Netherlands</p>
            <p>hi[at]sahilsingh.de</p>
          </div>
        </div>
        
        <div className="border-t border-gray-700 mt-12 pt-8 flex flex-col md:flex-row justify-between items-center">
          <p>&copy; {currentYear} Wellstone Consulting. All rights reserved.</p>
          <div className="mt-4 md:mt-0">
            <a 
              href="#hero"
              className="inline-block px-3 py-2 border border-white text-white hover:bg-white hover:text-primary transition-colors duration-300"
            >
              Back to Top
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer; 