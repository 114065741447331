import React from 'react';
import { motion } from 'framer-motion';

const Hero: React.FC = () => {
  return (
    <section id="hero" className="relative pt-32 pb-20 min-h-[90vh] flex items-center">
      {/* Swiss design background elements */}
      <div className="absolute inset-0 z-0 overflow-hidden">
        <div className="absolute top-24 right-24 w-48 h-48 rounded-full bg-accent opacity-10"></div>
        <div className="absolute bottom-24 left-24 w-64 h-64 rounded-full bg-primary opacity-5"></div>
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-96 h-96 rounded-full border-8 border-primary opacity-5"></div>
      </div>
      
      <div className="container-custom z-10 relative">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <motion.div 
              className="mb-4 inline-block bg-accent px-3 py-1"
              initial={{ opacity: 0, x: -10 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.4, delay: 0.1 }}
            >
              <span className="text-white font-medium">Amsterdam, NL (available remote)</span>
            </motion.div>
            
            <motion.h1 
              className="text-5xl md:text-6xl lg:text-7xl font-bold mb-6 leading-tight"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              Sahil Singh
            </motion.h1>
            
            <motion.p 
              className="text-xl md:text-2xl mb-8 max-w-xl swiss-typography"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.3 }}
            >
              Freelance Product Manager & Digital Strategist obsessed with efficiency, design, and digital products.
            </motion.p>
            
            <motion.div
              className="flex flex-wrap gap-4"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              <a href="#services" className="btn btn-primary">View Services</a>
              <a href="#contact" className="btn btn-secondary">Contact Me</a>
            </motion.div>
          </motion.div>
          
          <motion.div
            className="hidden lg:block"
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            <div className="relative w-full h-80 border-t-8 border-l-8 border-primary pt-8 pl-8">
              <div className="w-full h-full bg-gray-100"></div>
              <div className="absolute bottom-8 right-8 w-24 h-24 bg-accent"></div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Hero; 