import React from 'react';
import { motion } from 'framer-motion';

interface ClientProps {
  name: string;
  url: string;
  description: string;
  delay: number;
}

const Client: React.FC<ClientProps> = ({ name, url, description, delay }) => {
  return (
    <motion.div
      className="bg-white shadow-md p-6 hover:shadow-xl transition-all duration-300"
      initial={{ opacity: 0, y: 10 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true, amount: 0.3 }}
      transition={{ duration: 0.4, delay: delay * 0.3 }}
    >
      <div className="flex flex-col h-full">
        <div className="mb-4">
          <h3 className="text-xl font-bold">{name}</h3>
        </div>
        <p className="text-gray-700 mb-4 flex-grow">{description}</p>
        <a 
          href={url} 
          target="_blank" 
          rel="noopener noreferrer" 
          className="text-accent font-medium hover:underline"
        >
          Visit Website
        </a>
      </div>
    </motion.div>
  );
};

const Clients: React.FC = () => {
  const clients = [
    {
      name: "Medical Innovation Consulting",
      url: "https://www.mi-consulting.eu/",
      description: "Web Master",
      delay: 0.1
    },
    {
      name: "MattressJack",
      url: "https://www.mattressjack.com/",
      description: "Website Creation and E-commerce Strategy",
      delay: 0.2
    },
    {
      name: "DeinePflege",
      url: "https://www.deinepflege.de/",
      description: "Product Management, Process Setup, AI Automation",
      delay: 0.3
    },
    {
      name: "De Tuinders Tafel",
      url: "https://www.detuinderstafel.nl/",
      description: "Website Creation, Strategy Advisory, Booking Management",
      delay: 0.4
    },
    {
      name: "SenseMedia",
      url: "https://www.sensemedia.io/",
      description: "Conversion Rate Optimization, Digital Audits, Process Setup",
      delay: 0.5
    },
    {
      name: "housenumber.ai",
      url: "https://housenumber.ai/",
      description: "Ongoing Project",
      delay: 0.6
    }
  ];

  return (
    <section id="clients" className="py-24 bg-white">
      <div className="container-custom">
        <motion.div
          className="mb-16 text-center"
          initial={{ opacity: 0, y: 10 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.4 }}
        >
          <h2 className="text-4xl font-bold mb-4">Previous Clients</h2>
          <p className="text-xl max-w-3xl mx-auto text-gray-700">Selected work from 2021 to present.</p>
        </motion.div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {clients.map((client, index) => (
            <Client
              key={index}
              name={client.name}
              url={client.url}
              description={client.description}
              delay={client.delay}
            />
          ))}
        </div>
        
        <motion.div
          className="mt-16 text-center"
          initial={{ opacity: 0, y: 10 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.4, delay: 0.2 }}
        >
          <a href="#contact" className="btn btn-primary">Work With Me</a>
        </motion.div>
      </div>
    </section>
  );
};

export default Clients; 